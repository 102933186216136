import React, {Suspense, lazy, useEffect, useState, useMemo} from "react"
import defaultTheme from "./themes/defaultTheme";

import NavBar from "./components/navigation/NavBar";
import PageLoader from "./components/PageLoader";
import {ThemeProvider} from "@mui/material";
import {setWindowTitle} from "./utils/navigation";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Impress = lazy(() => import("./pages/Impress"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const pageNames: {[key: string]: string} = {
    "/": "Home",
    "/about": "About",
    "/contact": "Contact",
    "/impress": "Impress",
    "/privacy-policy": "Privacy Policy"
}
// Name, Route
const navItemsLeft: [string, string][] = [
    ["Home", "/"],
    ["Projects", "https://projects.software-city.org/"]
]
const navItemsRight: [string, string][] = [
    ["About", "/about"],
    ["Contact", "/contact"]
]
const navItemsSocial: [string, string][] = [
    ["GitHub", "https://github.com/Davis-Software"]
]

function App(){
    const [page, setPage] = useState(window.location.pathname || "/")

    useEffect(() => {
        window.addEventListener("popstate", () => {
            setPage(window.location.pathname)
        })
    }, [])
    useEffect(() => {
        setWindowTitle(pageNames[page] || page)

        if(window.location.pathname === page) return
        history.pushState(null, "", page)
    }, [page])

    const RenderPage = useMemo(() => {
        switch(page){
            case "/":
                return <Home />
            case "/about":
                return <About />
            case "/contact":
                return <Contact />
            case "/impress":
                return <Impress />
            case "/privacy-policy":
                return <PrivacyPolicy />
            default:
                return <NotFound />
        }
    }, [page])

    return (
        <ThemeProvider theme={defaultTheme}>
            <NavBar left={navItemsLeft} right={navItemsRight} social={navItemsSocial} />
            <Suspense fallback={<PageLoader />}>
                {RenderPage}
            </Suspense>
        </ThemeProvider>
    )
}

export default App;
